import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../_base-shared/contracts/laravel-response.interface';
import { Case } from '../../../_base-shared/models/Case/Case';
import { UserCaseDataValidation } from '../../../_base-shared/models/Case/UserCaseDataValidation';
import {
  UserCaseRelation,
  UserCaseRelationValidation,
} from '../../../_base-shared/models/Case/UserCaseRelationValidation';
import { PaymentInfoResource } from '../../../_base-shared/models/Payment/PaymentInfoResource';
import { PaymentRegistration } from '../../../_base-shared/models/Payment/PaymentRegistration';
import { PaymentTransaction } from '../../../_base-shared/models/Payment/Transaction/PaymentTransaction';
import { User } from '../../../_base-shared/models/User/User';
import { ClientBaseApiService } from './_shared/services/client-base-api.service';
import { PaymentTerm } from '../../../_base-shared/models/Payment/PaymentTerm';

interface NewCardRequest {
  payment_request_uuid?: string;
  amount: number;
  card_number: string;
  cvv: string;
  holder: string;
  expiry_month: string;
  expiry_year: string;
  brand: string;
}

@Injectable({
  providedIn: 'root',
})
export class ClientAppService extends ClientBaseApiService {
  private navSubjectSource = new BehaviorSubject<boolean>(false);
  public navSubject$       = this.navSubjectSource.asObservable();

  setNavSubject(isTrue: boolean) {
    this.navSubjectSource.next(isTrue);
  }

  paymentDetails(data) {
    return this.http.get<LaravelResourceResponse>(`${ this.apiUrl }/cases/${ data.uuid }/payment`, {params: data})
      .pipe(catchError(response => this.handleError(response)));
  }

  public getPaymentRequest(paymentRequestUuid: string, signature: string, relations: Array<string> = []) {
    const params = ClientBaseApiService.convertFiltersForRequest(
      {signature, with: relations}, 'get',
    );
    return this.http.get<LaravelResourceResponse<PaymentRequest>>(
      this.apiUrl + '/client/payment-requests/' + paymentRequestUuid, {params},
    ).pipe(catchError(response => this.handleError(response)));
  }

  public authorizePaymentRequest(paymentRequestUuid: string, data: { id_card: string, signature: string }) {
    return this.http.post<LaravelResourceResponse<Array<PaymentRegistration>>>(
      this.apiUrl + '/client/payment-requests/' + paymentRequestUuid + '/authorize', data,
    ).pipe(catchError(response => this.handleError(response)));
  }

  public getLatestUserCase(userUuid: string, relations: Array<string> = []) {
    const params = ClientBaseApiService.convertFiltersForRequest({with: relations}, 'get');
    return this.http.get<LaravelResourceResponse<Case>>(this.apiUrl + '/client/users/' + userUuid + '/latest-case',
      {params})
      .pipe(catchError(response => this.handleError(response)));
  }

  public getCaseByIdCard(id_card: any, relations: Array<string> = []) {
    const params = ClientBaseApiService.convertFiltersForRequest({...id_card, with: relations}, 'get');
    return this.http.get<LaravelResourceResponse<Case>>(
      this.apiUrl + '/public/users' + '/get-case-by-dni', {params},
    ).pipe(catchError(response => this.handleError(response)));
  }

  public indexPaymentCards(userUuid: string, caseUuid: string) {
    return this.http.get<LaravelResourceResponse<Array<PaymentRegistration>>>(
      this.apiUrl + '/client/users/' + userUuid + '/cases/' + caseUuid + '/payment-cards',
    ).pipe(catchError(response => this.handleError(response)));
  }

  public getPaymentCards(caseUuid: string) {
    return this.http.get<LaravelResourceResponse<Array<PaymentRegistration>>>(
      this.apiUrl + '/public/cases/' + caseUuid + '/payment-cards',
    ).pipe(catchError(response => this.handleError(response)));
  }

  public chargeExistingCard(userUuid: string, caseUuid: string,
                            data: { payment_request_uuid?: string, card_id: number, amount: number }) {
    return this.http.post<LaravelResourceResponse<PaymentTransaction>>(
      this.apiUrl + '/client/users/' + userUuid + '/cases/' + caseUuid + '/payment-cards/payments', data,
    ).pipe(catchError(response => this.handleError(response)));
  }

  public chargeNewCard(userUuid: string, caseUuid: string, data: NewCardRequest) {
    return this.http.post<LaravelResourceResponse<PaymentTransaction>>(
      this.apiUrl + '/client/users/' + userUuid + '/cases/' + caseUuid + '/payments', data,
    ).pipe(catchError(response => this.handleError(response)));
  }

  public paymentAmount(userUuid: string, caseUuid: string) {
    return this.http.get<LaravelResourceResponse<PaymentTerm>>(
      this.apiUrl + '/client/users/' + userUuid + '/cases/' + caseUuid + '/payment-amount',
    ).pipe(catchError(response => this.handleError(response)));
  }

  checkPaymentSatus(data) {
    return this.http.post<LaravelResourceResponse>(`${ this.apiUrl }/cases/${ data.uuid }/payment`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  getClientDocuments(caseId) {
    return this.http.get<LaravelResourceResponse>(`${ this.apiUrl }/cases/${ caseId }/documents`)
      .pipe(catchError(response => this.handleError(response)));
  }

  verifyCard(data) {
    return this.http.post<LaravelResourceResponse>(`${ this.apiUrl }/client/verification-payment/${ data.uuid }`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  payInitialFee(uuId: string, data) {
    return this.http.post<LaravelResourceResponse>(`${ this.apiUrl }/client/initial-payment/${ uuId }`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  saveSepaInfo(uuid, data) {
    return this.http.post<LaravelResourceResponse>(`${ this.apiUrl }/cases/${ uuid }/update-iban`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  getMymoidOrder(data: { payment_request: string, signature: string }) {
    return this.http.post<LaravelResourceResponse<{ type: string, base_url: string, short_code: string }>>(
      `${ this.apiUrl }/client/payment`, data,
    ).pipe(catchError(response => this.handleError(response)));
  }

  getPaymentInfo(transactionableUuid: string) {
    return this.http.get<LaravelResourceResponse<PaymentInfoResource>>(
      this.apiUrl + '/client/payment-info/' + transactionableUuid,
    ).pipe(catchError(response => this.handleError(response)));
  }

  optOut(data) {
    return this.http.get<LaravelResourceResponse>(`${ this.apiUrl }/client/unsubscribe`, {params: data})
      .pipe(catchError(response => this.handleError(response)));
  }

  optOutPublic(data) {
    return this.http.post<LaravelResourceResponse>(this.apiUrl + '/public/unsubscribe', data)
      .pipe(catchError(response => this.handleError(response)));
  }

  // TODO: move to public service
  notaryDownload(data): Observable<HttpResponse<Blob>> {
    return this.http.get(`${ this.apiUrl }/public/notary-download/get-files`, {
      params: data, observe: 'response', responseType: 'blob',
    }).pipe(catchError(response => this.handleError(response)));
  }

  showUser(userUuid: string) {
    return this.http.get<LaravelResourceResponse<User>>(this.apiUrl + '/client/users/' + userUuid)
      .pipe(catchError(response => this.handleError(response)));
  }

  updateUser(userUuid: string, userData) {
    return this.http.put<LaravelResourceResponse<User>>(this.apiUrl + '/client/users/' + userUuid, userData)
      .pipe(catchError(response => this.handleError(response)));
  }

  indexCases(userUuid: string) {
    return this.http.get<LaravelResourceResponse<Array<Case>>>(this.apiUrl + '/client/users/' + userUuid + '/cases')
      .pipe(catchError(response => this.handleError(response)));
  }

  showUserCase(userUuid: string, caseUuid: string, relations: Array<string> = []) {
    const params = ClientBaseApiService.convertFiltersForRequest({with: relations}, 'get');
    return this.http.get<LaravelResourceResponse<Case>>(
      this.apiUrl + '/client/users/' + userUuid + '/cases/' + caseUuid, {params},
    ).pipe(catchError(response => this.handleError(response)));
  }

  getCaseDepartmentUser(caseUuid: string, agentRole: string) {
    return this.http.get<LaravelResourceResponse<User>>(
      this.apiUrl + '/client/cases/' + caseUuid + '/department-agent', {params: {department_type: agentRole}},
    )
      .pipe(catchError(response => this.handleError(response)));
  }

  showCase(caseUuid: string) {
    return this.http.get<LaravelResourceResponse<Case>>(this.apiUrl + '/client/cases/' + caseUuid)
      .pipe(catchError(response => this.handleError(response)));
  }

  signValidation(caseUuid: string, userUuid: string, data) {
    return this.http.post<LaravelResourceResponse>(
      this.apiUrl + '/client/cases/' + caseUuid + '/users/' + userUuid + '/signature', data)
      .pipe(catchError(response => this.handleError(response)));
  }

  updateCase(caseUuid: string, caseData) {
    return this.http.put<LaravelResourceResponse<Case>>(this.apiUrl + '/client/cases/' + caseUuid, caseData)
      .pipe(catchError(response => this.handleError(response)));
  }

  uploadFiles(uuId, data): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(`${ this.apiUrl }/cases/${ uuId }/documents-upload`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  removeUploadedFile(uuId, fileId): Observable<LaravelResourceResponse> {
    return this.http.delete<LaravelResourceResponse>(`${ this.apiUrl }/cases/${ uuId }/documents-upload/${ fileId }`)
      .pipe(catchError(response => this.handleError(response)));
  }

  indexCaseRelation(caseUuid: string, userUUid: string, relation: UserCaseRelation | 'payment_plans.type') {
    return this.http.get<LaravelResourceResponse>(
      this.apiUrl + '/client/cases/' + caseUuid + '/users/' + userUUid + '/relations?relation=' + relation)
      .pipe(catchError(response => this.handleError(response)));
  }

  indexCaseDataValidation(caseUuid: string, userUUid: string) {
    return this.http.get<LaravelResourceResponse<UserCaseDataValidation>>(
      this.apiUrl + '/client/cases/' + caseUuid + '/users/' + userUUid + '/data-validations')
      .pipe(catchError(response => this.handleError(response)));
  }

  indexCaseRelationValidations(caseUuid: string, userUUid: string, relation: string) {
    return this.http.get<LaravelResourceResponse>(
      this.apiUrl + '/client/cases/' + caseUuid + '/users/' + userUUid + '/relation-validations?relation=' + relation)
      .pipe(catchError(response => this.handleError(response)));
  }

  updateCaseRelationValidation(caseUuid: string, userUUid: string, data: object) {
    return this.http.put<LaravelResourceResponse<UserCaseRelationValidation>>(
      this.apiUrl + '/client/cases/' + caseUuid + '/users/' + userUUid + '/relation-validations', data)
      .pipe(catchError(response => this.handleError(response)));
  }

  updateCaseDataValidation(caseUuid: string, userUuid: string, data: object) {
    return this.http.put<LaravelResourceResponse<UserCaseRelationValidation>>(
      this.apiUrl + '/client/cases/' + caseUuid + '/users/' + userUuid + '/data-validations', data)
      .pipe(catchError(response => this.handleError(response)));
  }

  indexCaseIncomeValidation(caseUuid: string, userUuid: string) {
    return this.http.get<LaravelResourceResponse>(
      this.apiUrl + '/client/cases/' + caseUuid + '/users/' + userUuid + '/income-validations')
      .pipe(catchError(response => this.handleError(response)));
  }

  indexCaseExpenseValidation(caseUuid: string, userUuid: string) {
    return this.http.get<LaravelResourceResponse>(
      this.apiUrl + '/client/cases/' + caseUuid + '/users/' + userUuid + '/expense-validations')
      .pipe(catchError(response => this.handleError(response)));
  }

  previewContract(caseUuid: string) {
    return this.http.get<LaravelResourceResponse>(
      this.apiUrl + '/client/cases/' + caseUuid + '/documents/contract-preview',
    ).pipe(catchError(response => this.handleError(response)));
  }

  previewMandate(caseUuid: string, clientRole: 'client' | 'partner') {
    return this.http.get<LaravelResourceResponse>(
      this.apiUrl + '/client/cases/' + caseUuid + '/documents/mandate-preview?signedBy' + clientRole,
    ).pipe(catchError(response => this.handleError(response)));
  }

  previewRightOfAccess(caseUuid: string, clientRole: 'client' | 'partner') {
    return this.http.get<LaravelResourceResponse>(
      this.apiUrl + '/client/cases/' + caseUuid + '/documents/right-of-access-preview?client_role=' + clientRole,
    ).pipe(catchError(response => this.handleError(response)));
  }

  previewAssetCreditorList(caseUuid: string, type: string) {
    return this.http.get<LaravelResourceResponse>(
      this.apiUrl + '/client/cases/' + caseUuid + '/documents/creditor-and-asset-list-preview?type=' + type,
    ).pipe(catchError(response => this.handleError(response)));
  }

  previewDebtCancellationRequest(caseUuid: string, signed: string) {
    return this.http.get<LaravelResourceResponse>(
      this.apiUrl + '/client/cases/' + caseUuid + '/documents/debt-cancellation-request-preview?signed_by=' + signed,
    ).pipe(catchError(response => this.handleError(response)));
  }

  saveDebtCancellationRequestSignature(caseId: number, data) {
    return this.http.post<LaravelResourceResponse>(`${ this.apiUrl }/cases/${ caseId }/debt-cancellation-request`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  previewProposal(caseUuid: string, signed: string) {
    return this.http.get<LaravelResourceResponse>(
      this.apiUrl + '/client/cases/' + caseUuid + '/documents/proposal-preview?signed_by=' + signed,
    ).pipe(catchError(response => this.handleError(response)));
  }

  saveProposalSignature(caseId: number, data) {
    return this.http.post<LaravelResourceResponse>(this.apiUrl + '/cases/' + caseId + '/proposal-v2', data)
      .pipe(catchError(response => this.handleError(response)));
  }
}
