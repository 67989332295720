import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Observable, Observer } from 'rxjs';

declare var document: any;

type SupportedScriptNames = 'connexLiveChat';

interface CustomScript {
  name: string;
  type: string;
  src: string;
  dataSocketUri?: string;
  id?: string;
  loaded?: boolean;
  async?: boolean;
  defer?: boolean;
}

export const ScriptStore: Array<CustomScript> = [
  {
    name:          'connexLiveChat',
    type:          'text/javascript',
    src:           'https://livechat-deudafixreparadoralegalgroupsl.connexone.co.uk/widget?connid=afb7e249-0d8e-4b4e-95c7-a4558c27fb62',
    dataSocketUri: 'https://livechat-deudafixreparadoralegalgroupsl.connexone.co.uk',
    id:            'livechat-deudafixreparadoralegalgroupsl',
    async:         false,
    defer:         false,
  },
];

@Injectable({
  providedIn: 'root',
})
export class ScriptLoaderService {

  private scripts: Array<CustomScript> = [];
  private renderer: Renderer2;

  constructor(private rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
    ScriptStore.forEach(script => {
      this.scripts.push({...script, loaded: false});
    });
  }

  public loadScript(script: CustomScript) {
    return this.load(script);
  }

  public loadScriptByName(scriptName: SupportedScriptNames) {
    const script = ScriptStore.find(s => s.name === scriptName);

    return this.load(script);
  }

  private load(script: CustomScript): Observable<CustomScript> {
    return new Observable<CustomScript>((observer: Observer<CustomScript>) => {
      const existingScript = this.scripts.find(s => s.name === script.name);

      if (existingScript && existingScript.loaded) {
        observer.next(existingScript);
        observer.complete();
      } else {
        this.scripts.push(script);
        const scriptIndex = this.scripts.findIndex(s => s.name === script.name);

        const scriptElement = document.createElement('script');
        scriptElement.type  = script.type;

        if (script.src) {
          scriptElement.src = script.src;
        }

        if (script.dataSocketUri) {
          scriptElement.setAttribute('data-socket-uri', script.dataSocketUri);
        }

        if (script.id) {
          scriptElement.id = script.id;
        }

        scriptElement.async = !!script.async;
        scriptElement.defer = !!script.defer;

        if (scriptElement.readyState) {  // IE
          scriptElement.onreadystatechange = () => {
            if (scriptElement.readyState === 'loaded' || scriptElement.readyState === 'complete') {
              scriptElement.onreadystatechange = null;
              script.loaded                    = true;
              this.scripts[scriptIndex]        = script;
              observer.next(script);
              observer.complete();
            }
          };
        } else {  // Others
          scriptElement.onload = () => {
            script.loaded             = true;
            this.scripts[scriptIndex] = script;
            observer.next(script);
            observer.complete();
          };
        }

        scriptElement.onerror = (error: any) => {
          observer.error('Couldn\'t load script ' + script.name);
        };

        this.renderer.appendChild(document.head, scriptElement);
      }
    });
  }

  public removejscssfile(script: SupportedScriptNames, filetype) {
    const targetelement = (filetype === 'js') ? 'script' : (filetype === 'css') ? 'link' : 'none';
    const targetattr    = (filetype === 'js') ? 'src' : (filetype === 'css') ? 'href' : 'none';
    const allsuspects   = document.getElementsByTagName(targetelement);
    for (let i = allsuspects.length; i >= 0; i--) {
      if (allsuspects[i] && allsuspects[i].getAttribute(targetattr) != null &&
        allsuspects[i].getAttribute(targetattr).indexOf(script) !== -1) {
        allsuspects[i].parentNode.removeChild(allsuspects[i]);
      }
    }
  }

  public appendScriptToBody(scriptContent: string, location: 'head' | 'body'): void {
    const scriptElement = this.renderer.createElement('script');
    scriptElement.innerHTML = scriptContent;
    const element = location === 'head' ? document.head : document.body;
   /* this.renderer.appendChild(element, scriptElement)*/
    if (location === 'head') {
      // Append before the closing </head> tag
      this.renderer.insertBefore(element, scriptElement, element.lastChild);
    } else {
      // Append immediately before the closing </body> tag
      this.renderer.appendChild(document.body, scriptElement);
    }
  }

  public appendNoscriptTagBeforeHead(name: string, elements: Array<{ tag: string, attributes: { [key: string]: string } }>): void {
    const noscriptElement = this.renderer.createElement(name);

    elements.forEach(element => {
      const childElement = this.renderer.createElement(element.tag);

      Object.entries(element.attributes).forEach(([attrName, attrValue]) => {
        this.renderer.setAttribute(childElement, attrName, attrValue);
      });

      this.renderer.appendChild(noscriptElement, childElement);
    });

    const headElement = document.head || document.getElementsByTagName('head')[0];
    this.renderer.insertBefore(headElement, noscriptElement, headElement.lastChild);
  }
}
